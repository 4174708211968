<template>
  <div class="modal fade" id="exampleImageModal" tabindex="-1">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body modal-body-gallery">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                <div id="exampleImageCarouselModal" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner" role="listbox">
                        <div v-for="(image, index) in images" :key="index" class="carousel-item" :class="[ image.path === imageIndex ? 'active' : '' ]">
                            <div class="carousel-item__inner">
                                <img :src="$filters.image(image.path)">
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev bg-transparent" href="#exampleImageCarouselModal" role="button" data-bs-slide="prev">
                        <i class="fas fa-chevron-left"></i>
                    </a>
                    <a class="carousel-control-next bg-transparent" href="#exampleImageCarouselModal" role="button" data-bs-slide="next">
                        <i class="fas fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const images = computed(() => {
      return store.getters['configurator/exampleImages']
    })
    const imageIndex = computed(() => {
      return store.getters['configurator/exampleImageIndex']
    })
    function closeModal () {
      const modal = document.querySelector('#exampleImageModal')
      const items = modal.querySelectorAll('.carousel-item')
      items.forEach(item => {
        item.classList.remove('active')
      })
      store.commit('configurator/SET_EXAMPLE_IMAGE_INDEX', null)
    }
    return {
      images,
      imageIndex,
      closeModal
    }
  }
}
</script>
