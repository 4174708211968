<template>
  <div class="row info-box mb-3">
      <div class="col-md-3 col-6">
        <div class="info-box__item">
          <i class="fas fa-map-marker"></i>
          <div class="info-box__item-right">
            <p class="title-secondary">Deutschlandweit</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="info-box__item">
          <i class="fas fa-certificate"></i>
          <div class="info-box__item-right">
            <p class="title-secondary">5 Jahre Garantie</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="info-box__item">
          <i class="fas fa-shield-alt"></i>
          <div class="info-box__item-right">
            <p class="title-secondary">Sichere Zahlungen</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-6">
        <div class="info-box__item">
          <i class="fas fa-wifi"></i>
          <div class="info-box__item-right">
            <p class="title-secondary">WiFi fähig</p>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    styleClass: {
      type: String
    }
  }
}
</script>
