<template>
  <div v-if="!previewImagesLoading" class="configurator-preview">
    <img v-for="(image, index) in images" :key="index" :src="$filters.image(image.path)"/>
    <button
      class="btn-reset btn-open-configurator"
      data-bs-toggle="modal"
      data-bs-target="#previewImageModal"
      @click="showModal"
    >
      <i class="fas fa-expand"></i>
    </button>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'PreviewImages',
  setup () {
    onMounted(() => {
      if (window.pageXOffset > 80) {
        document.querySelector('.header').classList.add('header--sticky')
      } else {
        document.querySelector('.header').classList.remove('header--sticky')
      }
      if (window.innerWidth < 576) {
        const imageHeight = document.querySelector('.configurator-preview > img:first-child').offsetHeight
        if (imageHeight > 0) {
          document.querySelector('.configurator-preview').style.height = `${imageHeight}px`
        }
      }
    })
    const store = useStore()
    const previewImagesLoading = computed(() => {
      return store.getters['configurator/previewImagesLoading']
    })
    const images = computed(() => {
      const images = store.getters['configurator/previewImages']
      images.sort((a, b) => (a.order > b.order) - (a.order < b.order))
      return images
    })
    function showModal () {
      store.commit('configurator/SET_PREVIEW_MODAL_SHOW', true)
    }
    return {
      images,
      previewImagesLoading,
      showModal
    }
  }
}
</script>
