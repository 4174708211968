<template>
    <div v-if="description" class="description" v-html="description"></div>
  </template>
<script>
export default {
  props: {
    description: {
      required: false,
      default: null
    }
  }
}
</script>
