<template>
  <div  class="modal fade" id="previewImageModal" tabindex="-1">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered">
        <div  class="modal-content">
            <div v-if="showModal" class="modal-body modal-body-gallery">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                <div v-if="images" class="configurator-preview configurator-preview--modal">
                    <img v-for="(item, index) in images" :src="$filters.image(item.path)" :key="index">
                </div>
            </div>
            <div v-else>
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
        </div>
    </div>
</div>

</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const images = computed(() => {
      return store.getters['configurator/previewImages']
    })
    const showModal = computed(() => {
      return store.getters['configurator/previewModalShow']
    })
    function closeModal () {
      store.commit('configurator/SET_PREVIEW_MODAL_SHOW', false)
    }
    return {
      images,
      showModal,
      closeModal
    }
  }
}
</script>
