<template>
  <div v-if="nav" class="configurator__steps" id="configuratorSteps">
    <div v-for="(step, index) in nav" :key="index" class="configurator__steps-item step">
        <div class="step__header">
          <button
            class="btn-reset step__open-btn collapsed"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse-' + step.id"
            aria-expanded="false"
            @click.prevent="changeCurrentStep(step.id)"
          >
            {{ step.name }}
          </button>
        </div>
        <div :id="'collapse-' + step.id" class="collapse" :class="[step.id === currentStep.id ? 'show' : '']" data-bs-parent="#configuratorSteps">
          <small v-if="step.title">{{ step.title }}</small>
          <Options />
          <div class="text-center mb-2">
            <button class="btn btm-sm btn-outline-primary" @click="nextStep" id="btn-scroll" :data-id="step.id">zum nächsten Schritt <i class="fas fa-arrow-right"></i></button>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import Options from './options'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'StepNavigation',
  components: {
    Options
  },
  setup () {
    const store = useStore()
    const nav = computed(() => {
      return store.getters['configurator/navigation']
    })
    const currentStep = computed(() => {
      return store.getters['configurator/currentStep']
    })
    onMounted(() => {
      const btns = document.querySelectorAll('#btn-scroll')
      btns.forEach(b => {
        b.addEventListener('click', () => {
          const el = document.getElementById('collapse-' + currentStep.value.id)
          const width = window.innerWidth
          if (width < 992) {
            window.scroll({ top: el.offsetTop - 180, behavior: 'smooth' })
          } else {
            window.scroll({ top: el.offsetTop + 100, behavior: 'smooth' })
          }
        })
      })
    })
    function changeCurrentStep (id) {
      store.dispatch('configurator/setCurrentStep', id)
    }
    function nextStep () {
      store.commit('configurator/SET_NEXT_STEP')
    }
    return {
      nav,
      currentStep,
      nextStep,
      changeCurrentStep
    }
  }
}
</script>
